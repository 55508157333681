import { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { UploadModal } from './components/UploadModal';
import ChatModal from './components/ChatModal';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import "./Tool.css"
import DeNeroBot from './components/DeNeroBot';
import Math1A from './components/Math1A';
import Data8 from './components/Data8';


function Tool(toolName) {
  const ChatContainer = (toolName) => {
    const name = toolName.toolName
    if (name === "tool") {
      return (
        <ChatModal className="chat-modal" />
      )
    }
    else if (name == "denero") {
      return (
        <DeNeroBot className="chat-modal" />
      )
    }
      else if (name == "data8") {
        return (
          <Data8 className="chat-modal" />
        )
      }
    
      else if (name == "math1a") {
        return (
          <Math1A className="chat-modal" />
        )
      }
  }
  return (
    <>      
      <div className="center">
      <Link to="/home"> 
      <div className="nav-bar">
        <FaHome className="home-logo" color="white"/>
      </div>
        </Link>
        <ChatContainer toolName={toolName.toolName}/>
        <p className="ad-text" style={{ color: "white", width: "70vw"}}><b><a href="https://twitter.com/KoiosEDU" style={{ color: "lightblue" }}>Follow us on Twitter</a> for updates and early access to new features and academic assistants. PS: Our first 200 followers get a special gift in May.</b></p>
      </div>
    </>
    )
  }
  
  export default Tool;

  