import React from 'react'
import ReactDOM from 'react-dom/client'
import Tool from './Tool'
import ChatModal from './components/ChatModal.js'
import UploadModal from './components/UploadModal.js'
import Hand from './components/Hand.jsx'
import './index.css'
import Landing from './Landing.jsx'
import Directory from './Directory.jsx'

ReactDOM.createRoot(document.getElementById('root')).render(

<Directory />
 
)
