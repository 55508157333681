
import { React, useState } from "react";
import axios from "axios";
import "./UploadModal.css";
import {Route, useNavigate, Navigate} from 'react-router-dom';
import { defineStyle, defineStyleConfig, Spinner } from '@chakra-ui/react'
import {Link} from "react-router-dom";

export const UploadModal = () => {
    const [file, setFile] = useState("");
    const [uploaded, setUploaded] = useState(false)
    const [key, setKey] = useState("")
    const [processingUpload, setProcessingUpload] = useState(false);
    if (uploaded) {
      return (        
        <Navigate to={`/sayge/${key}`}/>
      )
    }
    function handleChange(event) {
        setFile(event.target.files[0]);
    }

    function handleSubmit(event) {
        setProcessingUpload(true);
        console.log("here");
        event.preventDefault()
        const url = 'https://150-136-76-195.sslip.io/uploadFile';
        //const url = 'http://127.0.0.1:5000/uploadFile';
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', file.name);
        console.log(file);
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        axios.post(url, formData, config).then((response) => {
          console.log("HERE")
          console.log(response.data.key)
          setKey(response.data.key);
          setProcessingUpload(false);
          setUploaded(true);
        }, (error) => {
          window.alert("Error processing your document. Your current document is not supported. Please try a different document.")
          setProcessingUpload(false);
        });
    
    }

    if (processingUpload) {
      return (
        <>
          <div className="modal">
              <div className="modalHeader">
              <h5 className="heading">Processing Your Document...</h5>
              </div>
              <Spinner
                thickness='1.2vw'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
                borderRadius='0vw'
                marginTop='3vw'
              />
          </div>
        </>
      )
    }

    return (
      <>
          <div className="modal">
              <div className="modalHeader">
              <h5 className="heading">Upload Your PDF</h5>
              </div>
              <div className="modalContent">
                  <input type="file" onChange={handleChange}/>
                  <p textColor="white" >This can be a textbook, reading, academic paper, or anything else (as long it's a PDF!)</p>
                  <button type="submit1" onClick={handleSubmit}>Upload</button>
                  <br></br>
                  <br></br>
                  <hr></hr>
                  <div className="denero-text">
                    Nothing to upload? Chat with Berkeley's CS professor John DeNero or the Math 1A textbook.  
                  </div>
                  <br></br>
                  <Link to="/denero">
                  <button type="submit">CS61A</button>&nbsp;&nbsp;
                  </Link>
                  {/* <Link to="/data8">
                  <button type="submit">DATA 8</button>&nbsp;&nbsp;
                  </Link> */}
                  <Link to="/math1a">
                  <button type="submit">MATH 1A</button>&nbsp;&nbsp;
                  </Link>
              </div>
          </div>
      </>
    );
};