import { React, useState, useParams } from "react";
import {Route, useNavigate, Navigate} from 'react-router-dom';

export default async function delKey(key) {
    console.log("delKey called!")
    console.log(key);
    await fetch(
        // "https://150-136-76-195.sslip.io/delkey?" 
        "http://127.0.0.1:5000/delkey?"
        + new URLSearchParams({
        "key": key
        }),
        {
            method: "GET",
            headers: {
            "Content-Type": "application/json"
            },
        }).then((data) => {
            console.log(data)
        })
}