import React from 'react';
import Hand from './components/Hand.jsx'
import Koios from './components/Koios.jsx'
import { Button, ButtonGroup } from '@chakra-ui/react'
import './Landing.css'
import {Link} from "react-router-dom";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-261148127-1');
ReactGA.pageview(window.location.pathname + window.location.search);


function Landing() {
  const styles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'radial-gradient(125.5% 125.5% at 48.6% 100%, #a200ff 0%, #020431 100%)',
    overflow: 'hidden',
    padding: '0px',
    alignContent: 'center',
    flexWrap: 'nowrap',
    gap: '0',
    position: 'absolute',
    borderRadius: '0px',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    margin: '0',
    height: '100vh',
    width: '100vw'
  };

  const headerStyles = {
    fontSize: '48px',
    fontWeight: 'bold',
    color: '#fff',
    textShadow: '2px 2px #000'
  };

  return (
    <div className="container">
      
      <div className="left">
        <Koios className="watermark"></Koios>
        <h1 className="introducing-sayge">Introducing Sayge</h1>
        <p className="text123">Got a long reading or textbook? Upload your file and ask Sayge to summarize, quiz, or otherwise support your content mastery.</p>
        <Link to="/upload"> 
          <Button className="ask-button" colorScheme="teal" width="12vw">
            Ask Sayge
          </Button>
        </Link>
      </div>
      <div className="right">
        <Hand></Hand>
      </div>

    </div>
  );
  
}

export default Landing;
