import { UploadModal } from './components/UploadModal.js';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import "./Tool.css";

export const UploadPage = () => {
    return (
        <>
            <Link to="/home"> 
            <div className="nav-bar">
                <FaHome className="home-logo" color="white"/>
            </div>
            </Link>
            <UploadModal/>
        </>
    )
}