import { useState } from 'react'
import './ChatModal.css'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator } from '@chatscope/chat-ui-kit-react';


const Data8 = () => {
  const [messages, setMessages] = useState([
    {
      message: "Hi, I'm a virtual representation of the Data 8 Textbook: Computational and Inferential Thinking. Ask me anything about Data 8!",
      sentTime: "just now",
      sender: "Data8"
    }
  ]);

  const [isTyping, setIsTyping] = useState(false);

  const handleSend = async (message) => {
    if (!isTyping) {
      const newMessage = {
        message,
        direction: 'outgoing',
        sender: "user"
      };

      const newMessages = [...messages, newMessage];
      
      setMessages(newMessages);

      // Initial system message to determine ChatGPT functionality
      // How it responds, how it talks, etc.
      setIsTyping(true);
      await processMessageToChatGPT(newMessages);
    }
  };

  async function processMessageToChatGPT(chatMessages) { // messages is an array of messages
    // Format messages for chatGPT API
    // API is expecting objects in format of { role: "user" or "assistant", "content": "message here"}
    // So we need to reformat

    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      return { role: role, content: messageObject.message}
    });
    
    console.log("LOG")
    console.log(apiMessages)
    console.log(apiMessages.content)
    await fetch(
      "https://150-136-76-195.sslip.io/getDataResponse", 
      //"http://127.0.0.1:5000/getDataResponse",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(apiMessages)
    }).then((data) => {
      console.log(data)
      return data.json();
    }).then((data) => {
      console.log("DAFSDFD")
      console.log(data);
      console.log("DAFSDFD")
      setMessages([...chatMessages, {
        message: data.response,
        sender: "ChatGPT"
      }]);
      setIsTyping(false);
    })
  }
  return (
    <div className="App">
    <div style={{ 
      position:"relative", 
      height: "80vh", 
      width: "70vw",
      boxShadow: "0 0 100px rgba(0, 0, 0, .65)",
    }}>
        <MainContainer>
          <ChatContainer>       
            <MessageList 
              scrollBehavior="smooth" 
              typingIndicator={isTyping ? <TypingIndicator content="Data 8 is typing" /> : null}
            >
              {messages.map((message, i) => {
                console.log(message)
                return <Message key={i} model={message} />
              })}
            </MessageList>
            <MessageInput placeholder="Type message here" onSend={handleSend} />        
          </ChatContainer>
        </MainContainer>
      </div>
    </div>
  )
}

export default Data8
