import React from 'react';
import completedFile from '../assets/X-10.svg';

const ImageDisplay = () => {
  const imageStyles = {
    maxWidth: '750px',
    maxHeight: 'auto',
  };
  
  
  return (
    <div>
      <img src={completedFile} alt="Completed File" style={imageStyles} />
    </div>
  );
};

export default ImageDisplay;
