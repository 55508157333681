import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Landing from './Landing'
import Tool from './Tool'
import { UploadPage } from './UploadPage';

export default function App() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route index element={<Landing />} />
                    <Route path="/home" element={<Landing />} />
                    <Route path="/upload" element={<UploadPage/>} />
                    <Route exact path="/sayge/:key" element={<Tool toolName="tool"/>}/>
                    <Route path="denero" element={<Tool toolName="denero"/>}/>
                    {/* <Route path="data8" element={<Tool toolName="data8"/>}/> */}
                    <Route path="math1a" element={<Tool toolName="math1a"/>}/>
                    <Route path="*" element={<Landing />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}