import React from 'react';
import Koios from '../assets/Koios123.png';
import '../Landing.css'
const ImageDisplay = () => {

  return (
    <div className="image-div">
      <img className="image-style" src={Koios} alt="Completed File" />
    </div>
  );
};

export default ImageDisplay;
