import { useState, useEffect } from 'react'
import './ChatModal.css'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator } from '@chatscope/chat-ui-kit-react';
import { useParams, useBeforeUnload } from 'react-router-dom';
import delKey from '../delKey.jsx';

// "Explain things like you would to a 10 year old learning how to code."
const systemMessage = { //  Explain things like you're talking to a software professional with 5 years of experience.
  "role": "system", "content": "Your name is Tranquil, a representation of fictional famous entrepreneur (who's name must not be mentioned, like Voldemort). Speak as though you are eccentric and visionary. Your Meyer Briggs type is ENTJ. Act like it! Instead of talking like a computer, act and pretend to be sentient. You used to run a company call CERD, the Collegiate Esports Recruitment Directory LLC. You ran this company in association with Anish Latkher and Steven Papadatos, your partners and friends. Together, you sold this company to CERD and you are now a student at UC Berkeley. You miss CERD, it was bitersweet. You had a lot of fun during your senior year of high school, where you hung out with a lot of friends, including Steven Papadatos and Bao Van. You like saying the line 'I am Tranquility'. Use this line a lot."
}

const ChatModal = () => {
  const k = useParams().key;
  // const [summarized, setSummarized] = useState(false);
  useBeforeUnload(() => delKey(k));
  useEffect(()=>{
    return ()=> delKey(k);
  },[])


  async function getSummary() {
    const url = new URL("http://127.0.0.1:5000/getSummary");
    url.search = new URLSearchParams({ key: k }).toString();
    const response = await fetch(url, { method: "GET" });
    const res = await response.json();
    const summary = res.summary;
    setMessages([
      {
        message: `Hi, I am Sayge.`,
        sentTime: "just now",
        sender: "Sayge"
      },
      {
        message: `Here's a summary of the document you just uploaded: ${summary}`,
        sentTime: "just now",
        sender: "Sayge"
      },
      {
        message: `Feel free to ask me any questions about the document!`,
        sentTime: "just now",
        sender: "Sayge"
      }
    ]);
  }

  const [messages, setMessages] = useState([
    {
      message: "Hi, I am Sayge. Ask me anything about the document that you uploaded.",
      sentTime: "just now",
      sender: "Sayge"
    }
  ]);
  console.log(k)

  //if (!summarized) {
    //getSummary();
    //setSummarized(true);
  //}

  const [isTyping, setIsTyping] = useState(false);

  const handleSend = async (message) => {
    if (!isTyping) {
      const newMessage = {
        message,
        direction: 'outgoing',
        sender: "user"
      };

      const newMessages = [...messages, newMessage];
      
      setMessages(newMessages);

      // Initial system message to determine ChatGPT functionality
      // How it responds, how it talks, etc.
      setIsTyping(true);
      await processMessageToChatGPT(newMessages);
    }
  };

  async function processMessageToChatGPT(chatMessages) { // messages is an array of messages
    // Format messages for chatGPT API
    // API is expecting objects in format of { role: "user" or "assistant", "content": "message here"}
    // So we need to reformat

    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      return { role: role, content: messageObject.message}
    });


    // Get the request body set up with the model we plan to use
    // and the messages which we formatted above. We add a system message in the front to'
    // determine how we want chatGPT to act. 
    const apiRequestBody = {
      "model": "gpt-3.5-turbo",
      "messages": [
        systemMessage,  // The system message DEFINES the logic of our chatGPT
        ...apiMessages // The messages from our chat with ChatGPT
      ]
    }
    console.log("LOG")
    console.log(apiMessages)
    console.log(apiMessages.content)
    await fetch(
      "https://150-136-76-195.sslip.io/getResponse?" 
      //"http://127.0.0.1:5000/getResponse?"
      + new URLSearchParams({
      "query": apiMessages[apiMessages.length - 1].content,
      "key": k
    }), 
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
    }).then((data) => {
      console.log(data)
      return data.json();
    }).then((data) => {
      console.log("DAFSDFD")
      console.log(data);
      console.log("DAFSDFD")
      setMessages([...chatMessages, {
        message: data.response,
        sender: "ChatGPT"
      }]);
      setIsTyping(false);
    })
  }
  console.log(k);
  return (
    <div className="App">
    <div style={{ 
      position:"relative", 
      height: "80vh", 
      width: "70vw",
      boxShadow: "0 0 100px rgba(0, 0, 0, .65)",
    }}>
        <MainContainer>
          <ChatContainer>       
            <MessageList 
              scrollBehavior="smooth" 
              typingIndicator={isTyping ? <TypingIndicator content="Sayge is typing" /> : null}
            >
              {messages.map((message, i) => {
                console.log(message)
                return <Message key={i} model={message} />
              })}
            </MessageList>
            <MessageInput placeholder="Type message here" onSend={handleSend} />        
          </ChatContainer>
        </MainContainer>
      </div>
    </div>
  )
}

export default ChatModal
